@use 'sass:math';

@use '_colors' as c;

/* Generic Font Tag Setup */
strong,
.callout {
  font-weight: 600;
}

h1,
.h1,
h2,
.h2 {
  font-weight: 700;
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  letter-spacing: -0.0625rem;
}

h5,
.h5 {
  letter-spacing: 0.25rem;
}

h6,
.h6 {
  letter-spacing: 0.1875rem;
}

h5,
.callout {
  display: inline-block;
  font-size: 1.25em;
}

/* Typography Classes for Element Flexibility */

h1,
.h1 {
  font-size: 3.5rem; // 56px
  line-height: 1.036;
}

h2,
.h2 {
  font-size: 2.5rem; // 40px
  line-height: 1.15;
}

h3,
.h3,
.header-title {
  font-size: 2rem; // 32px
  line-height: 1.125;
}

h4,
.h4 {
  font-size: 1.375rem; // 22px
  line-height: 1.091;
}

h5,
.h5 {
  font-size: 1.125rem; // 18px
  line-height: 1.333;
}

.h6 {
  font-size: 0.875rem; // 14px
  line-height: 1.286;
}

.kicker {
  letter-spacing: 0.225rem;

  &,
  &-large,
  &-small {
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
  }

  &-large {
    font-size: 1.125rem;
    letter-spacing: 0.25rem;
  }

  &-small {
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
  }
}

// content header styles
.ch\: {
  &kicker {
    font-size: 1.125rem; // 18px
    letter-spacing: 0.225rem;

    &,
    &-small {
      display: inline-block;
      font-weight: 600;
      text-transform: uppercase;
    }

    &-small {
      font-size: 0.875rem;
      letter-spacing: 0.125rem;
    }
  }

  &subhead,
  &subtitle {
    display: inline-block;
    font-size: 1.125rem; // 18px
    font-weight: 600;
    line-height: 1.333;
  }

  &card-sub {
    display: inline-block;
    font-size: 1.125rem; // 18px
    font-weight: 400;
    line-height: 1.5;

    &:not([class*='c\:']) {
      color: var(--copy);
    }
  }

  &link {
    color: var(--accent-primary);
    transition: opacity 0.2s ease;

    &:hover,
    :focus {
      opacity: 0.6;
    }
  }
}

.body {
  &-large {
    font-size: 1.125rem; // 18px
  }

  &-small {
    font-size: 1rem; // 16px
  }
}

.caption {
  &-large {
    font-size: 0.875rem; // 14px
  }

  &-small {
    font-size: 0.75rem; // 12px
  }
}

// Text overflow ellipses
.overflow-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ovf\:hidden {
  overflow: hidden;
}

/* Common utility classes */

$fontSizing: (
  '12': 12,
  '14': 14,
  '16': 16,
  '18': 18,
  '20': 20,
  '22': 22,
  '24': 24,
  '32': 32,
  '36': 36,
  '40': 40,
  '44': 44,
  '48': 48,
  '64': 64,
  '72': 72,
  '80': 80,
);

@each $class, $value in $fontSizing {
  .e\:#{$class} {
    font-size: math.div($value, 16) + em;
  }
  .r\:#{$class} {
    font-size: math.div($value, 16) + rem;
  }
}

.ta\: {
  &center {
    text-align: center;
  }

  &right {
    text-align: right;
  }

  &left {
    text-align: left;
  }
}

// TODO - Reassess after app migration
.lh\: {
  &1 {
    line-height: 1;
  }

  &1\.2 {
    line-height: 1.2;
  }

  &1\.33 {
    line-height: 1.33;
  }

  &1\.5 {
    line-height: 1.5;
  }

  &2 {
    line-height: 2;
  }
}

.fw\: {
  &300,
  &light {
    font-weight: 300;
  }

  &400,
  &normal {
    font-weight: 400;
  }

  &500,
  &medium {
    font-weight: 500;
  }

  &600,
  &semibold,
  &semi-bold {
    font-weight: 600;
  }

  &700,
  &bold {
    font-weight: 700;
  }

  &800 {
    font-weight: 800;
  }
}

[class*='ul\:'] {
  text-decoration: underline;
}

.ul\: {
  &line {
    text-decoration-style: solid;
  }

  &dashed {
    text-decoration-style: dashed;
  }

  &double {
    text-decoration-style: double;
  }

  &wavy,
  &spellcheck {
    text-decoration-style: wavy;
  }

  &spellcheck {
    text-decoration-color: var(--red);
  }

  &dotted,
  &tooltip {
    text-decoration-style: dotted;
  }
}

/* Color Mod */
@each $name, $val in c.$content-colors {
  .c\:#{$name} {
    color: var(--#{$name});
  }
}

/* List styles */
ul.list\:4 {
  margin-bottom: 4rem;

  &:not([class*='nlf']) {
    display: flex;
    flex-direction: column;
  }

  li {
    position: relative;
    padding-left: 1.5rem;

    + li {
      margin-top: 0.5rem;
    }

    &::before {
      content: '';
      width: 4px;
      height: 4px;
      left: 0;
      top: 0.5em;
      border-radius: 50%;
      background-color: var(--copy);
      display: block;
      position: absolute;
    }
  }
}

/* List styles */

.list\:select-item {
  position: relative;
  border-bottom: solid 1px var(--grey4);
  cursor: pointer;

  &:not([class*='nlp-']) {
    padding-block: 0.375rem;
  }

  &::after {
    content: '';
    position: absolute;
    border: solid 2px transparent;
    border-radius: 0.5rem;
    width: 100%;
    height: calc(100% - 0.5rem);
    left: 0;
    top: 0.25rem;
    transition:
      border 0.2s ease,
      background-color 0.2s ease;
  }

  &:hover::after {
    border-color: var(--ui-tertiary);
  }

  &.active::after {
    border-color: var(--accent-primary);
  }

  &:not([class*='nlf-']) {
    display: block;
  }
}

@media (min-width: 769px) {
  .list\:select-item {
    &:not([class*='nlp-']) {
      padding-inline: 0.625rem;
    }
  }
}

@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: 2.5rem; // 40px
    line-height: 1.15;
  }

  h2,
  .h2 {
    font-size: 2rem; // 32px
    line-height: 1.125;
  }

  h3,
  .h3,
  .header-title {
    font-size: 1.25rem; // 20px
    line-height: 1.3;
  }

  h4,
  .h4 {
    font-size: 1.125rem; // 18px
    line-height: 1.333;
  }

  h5,
  .h5 {
    font-size: 1rem; // 16px
    line-height: 1.5;
  }

  .kicker {
    letter-spacing: 0.225rem;

    &-large {
      font-size: 1rem; // 16px
      letter-spacing: 0.1875rem;
    }
  }

  // content header styles
  .ch\: {
    &kicker {
      font-size: 1rem;
      letter-spacing: 0.225rem;
    }

    &subhead,
    &subtitle {
      font-size: 1.0625rem;
      line-height: 1.5rem;
    }
  }
  .list\:select-item {
    &:not([class*='nlp-']):not(.no-mobile-horz) {
      padding-inline: 0.625rem;
    }
  }
}
