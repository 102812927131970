@use '_layout' as l;

.dp\:block,
.dp\:b {
  display: block;
}

.dp\:inline,
.dp\:i {
  display: inline;
}

.dp\:inline-block,
.dp\:ib {
  display: inline-block;
}

/* Element Sizing Collection */
.h-0 {
  height: 0;
}

.full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.max-full {
  max-width: 100%;
}

.half {
  width: 50%;
}

// TODO - Reassess after app migration
.max-half {
  max-width: 50%;
}

.third {
  width: 33.33%;
}

.max-third {
  max-width: 33.33%;
}

.two-thirds {
  width: 66.67%;
}

.max-two-thirds {
  max-width: 66.67%;
}

.fourth {
  width: 25%;
}

.max-fourth {
  max-width: 25%;
}

.three-fourths {
  width: 75%;
}

.max-three-fourths {
  max-width: 75%;
}

.fifth {
  width: 20%;
}

.two-fifths {
  width: 40%;
}

.three-fifths {
  width: 60%;
}

.four-fifths {
  width: 80%;
}

.sixth {
  width: 16.66%;
}

.five-sixths {
  width: 83.33%;
}

/* Element Sizing Collection */

/* Empty Router Outlet */
router-outlet:empty {
  display: none;
}

/* Scroll */
// TODO - Reassess after app migration
.ui-scroll,
.ui-scroll--light {
  @include l.scrollbar();
}

.ui-scroll--dark {
  @include l.scrollbar('dark');
}

.ui-scroll--light-transparent,
.ui-scroll--lt {
  @include l.scrollbar('light-transparent');
}

.ui-scroll--dark-transparent,
.ui-scroll--dt {
  @include l.scrollbar('dark-transparent');
}

.wizard-divider {
  @include l.divider(3);
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.cursor\:pointer {
  cursor: pointer;
}

// ADA hide
.ada-hide {
  @include l.ada-hide();
}

// Suite v2
:root {
  --app-100vh: min(100dvh, 100vh);
  --global-header-height: 3rem; // 48px
  --global-bottom-gap: 1rem; // 48px
  --page-header-height: 4.5rem; // 56px
  --global-side-panel-width: 3.5rem; // 56px
  --global-side-gaps-width: calc(var(--global-side-panel-width) + 8px); // 64px, 8px for blue side bar
  --global-left-nav-width: 5rem;
  --bottom-nav-height: calc(5.063rem + env(safe-area-inset-bottom)); // 81px
}

// Suite v2 Menu Modals
.suite-shell-scroll {
  max-height: 100%;
  flex: 1 1 auto;
  padding: var(--pop-side-pad);
  overflow: auto;
  @include l.scrollbar('light', 0, 1);
}

.suite-shell-separator {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 3rem);
    height: 1px;
    top: 0;
    left: -1.5rem;
    background-color: var(--grey4);
  }

  &.suite-shell-scroll::before {
    width: calc(100% + 1.5rem);
  }
}

.center-spinner {
  display: block;
  height: calc(100vh - 20rem);
}

@media screen and (max-width: 768px) {
  :root {
    --global-header-height: calc(3.5rem + env(safe-area-inset-top)); // 56px
    --page-header-height: 3.5rem; // 56px
    --global-side-panel-width: 0;
  }

  .suite-shell-scroll {
    width: calc(100% + 2.25rem);
    margin-left: -1.125rem;
    padding-right: 1.125rem;
    padding-left: 1.125rem;
    @include l.scrollbar('light', 0, 1);
  }
}

// Page Framework
.page-framework-disputes-grid-container-margin-minus-right-scroll-width {
  > div:nth-of-type(1) {
    // Chrome
    margin-right: calc(var(--scroll-width) * -1);

    // Firefox
    @include l.only-firefox {
      margin-right: 0;
    }

    // Safari
    @include l.only-safari {
      margin-right: var(--scroll-width);
    }
  }
}

.page-framework-disputes-padding-minus-right-scroll-width {
  // Chrome / Firefox
  padding-right: calc(var(--scroll-width) * -1);

  // Safari
  @include l.only-safari {
    padding-right: var(--scroll-width);
  }
}
