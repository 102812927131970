/* Styles included as the base of most scorpion apps */

@use '_colors' as c;
@use '_colors_styles'; // Should only be @used here
@use '_layout' as l;
@use '_layout_styles'; // Should only be @used here
@use '_flex'; // Should only be @used here
@use '_content'; // Should only be @used here
@use '_forms_styles'; // Should only be @used here
@use '_table_styles'; // Should only be @used here

body,
html {
  accent-color: var(--accent-primary); // Adds color to browser components (like default form elements)
  color: var(--ui-primary);
  margin: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  /* Fix for Safari taking liberty with font-sizes: */
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  // Currently hardcoded due to complications with layout scrollbar mixin and specificity
  --scroll-width: 14px;
  @include l.scrollbar();
  overflow-y: overlay;

  &::-webkit-scrollbar-thumb {
    background-color: scale-color(c.$grey2, $alpha: -35%);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: scale-color(c.$grey2, $alpha: -35%);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:focus {
  outline: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: inline-block;
}

a {
  font-weight: 500;
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
