.input-text {
  position: relative;
}

.label-buffer {
  padding-top: 1.4rem;
}

input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1em;
  color: inherit;
}

button {
  outline: none;
  font-size: 1em;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  text-align: left;
}

.validator-list {
  margin-top: 1rem !important;
  display: none;

  .con:focus-within + &,
  scorpion-input:focus-within + &,
  scorpion-password:focus-within + &,
  scorpion-textarea:focus-within + & {
    display: flex;
  }
}
