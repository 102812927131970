@use 'colors' as c;

// Mixins at the top for reference in base styles below
// Light Mode CSS Color Variables
@mixin light-vars {
  // Base CSS Color Variables
  --blue1: var(--blue1-light); // Accent Primary
  --blue2: var(--blue2-light); // Accent Secondary
  --blue2-alt: var(--blue2-alt-light); // Selected
  --blue3: var(--blue3-light); // Accent Tertiary/Focused
  --blue3-alt: var(--blue3-alt-light); // ??? Selected alt
  --blue4: var(--blue4-light); // Accent Quaternary
  --blue5: var(--blue5-light); // Accent Quinary
  --grey1: var(--grey1-light); // UI Background
  --grey2: var(--grey2-light); // UI Tertiary
  --grey2-alt: var(--grey2-alt-light); // Hover
  --grey3: var(--grey3-light); // Copy
  --grey3-alt: var(--grey3-alt-light);
  --grey4: var(--grey4-light); // Inactive / Lines
  --grey5: var(--grey5-light); // UI Foreground
  --white: var(--white-light); // Container
  --scorp-black: var(--scorp-black-light); // UI Primary
  --grey3-secondary: var(--grey3-secondary-light); // UI Secondary
  --grey3-secondary-trigger: var(--grey3-secondary-trigger-light); // UI Secondary Trigger

  // Semantic CSS Variables
  --accent-primary: var(--blue1);
  --accent-secondary: var(--blue2);
  --accent-tertiary: var(--blue3);
  --accent-quaternary: var(--blue4);
  --accent-quinary: var(--blue5);
  --selected: var(--blue2-alt);
  --focused: var(--blue3);
  --hover: var(--grey2-alt);
  --ui-background: var(--grey1);
  --ui-foreground: var(--grey5);
  --container: var(--white);
  --ui-primary: var(--scorp-black);
  --ui-primary-reversed: var(--white);
  --copy: var(--grey3);
  --ui-secondary: var(--grey3-secondary);
  --ui-secondary-trigger: var(--grey3-secondary-trigger);
  --ui-tertiary: var(--grey2);
  --inactive: var(--grey4);
  --accent-hero: var(--accent-primary);

  // App Colors
  --platform-primary: var(--platform-primary-light);
  --platform-secondary: var(--platform-secondary-light);
  --marketing-secondary: var(--marketing-secondary-light);
  --rep-secondary: var(--rep-secondary-light);
  --comms-secondary: var(--comms-secondary-light);
  --ops-secondary: var(--ops-secondary-light);
  --payments-secondary: var(--payments-secondary-light);
  --reporting-secondary: var(--reporting-secondary-light);
  --customers-secondary: var(--customers-secondary-light);
  --settings-secondary: var(--settings-secondary-light);
  --settings-gradient1: var(--settings-gradient1-light);
  --settings-gradient2: var(--settings-gradient2-light);

  // NPS Colors
  --nps-positive: var(--nps-positive-light);
  --nps-negative: var(--nps-negative-light);
  --nps-positive-content: var(--nps-positive-content-light);
  --nps-negative-content: var(--nps-negative-content-light);

  // Chart Colors
  --chart-qualitative-1: var(--chart-qualitative-1-light);
  --chart-qualitative-2: var(--chart-qualitative-2-light);
  --chart-qualitative-3: var(--chart-qualitative-3-light);
  --chart-qualitative-4: var(--chart-qualitative-4-light);
  --chart-qualitative-5: var(--chart-qualitative-5-light);
  --chart-qualitative-6: var(--chart-qualitative-6-light);
  --chart-qualitative-7: var(--chart-qualitative-7-light);
  --chart-qualitative-8: var(--chart-qualitative-8-light);
  --chart-qualitative-9: var(--chart-qualitative-9-light);
  --chart-qualitative-10: var(--chart-qualitative-10-light);

  --chart-diverging-1: var(--chart-diverging-1-light);
  --chart-diverging-2: var(--chart-diverging-2-light);
  --chart-diverging-3: var(--chart-diverging-3-light);
  --chart-diverging-4: var(--chart-diverging-4-light);
  --chart-diverging-5: var(--chart-diverging-5-light);
  --chart-diverging-6: var(--chart-diverging-6-light);
  --chart-diverging-7: var(--chart-diverging-7-light);
  --chart-diverging-8: var(--chart-diverging-8-light);
  --chart-diverging-9: var(--chart-diverging-9-light);
  --chart-diverging-10: var(--chart-diverging-10-light);

  // Misc Colors
  --green: var(--green-light);
  --green-auxiliary: var(--green-auxiliary-light); // var(--green) @ 20%
  --green-stroke: var(--green-stroke-light);
  --yellow: var(--yellow-light);
  --yellow-auxiliary: var(--yellow-auxiliary-light); // var(--yellow) @ 20%
  --yellow-stroke: var(--yellow-stroke-light);
  --red: var(--red-light);
  --red-auxiliary: var(--red-auxiliary-light); // var(--red) @ 20%
  --red-stroke: var(--red-stroke-light);
  --burgundy: var(--burgundy-light);
  --burgundy-auxiliary: var(--burgundy-auxiliary-light);
  --burgundy-stroke: var(--burgundy-stroke-light);
  --brown: var(--brown-light);
  --brown-auxiliary: var(--brown-auxiliary-light);
  --brown-stroke: var(--brown-stroke-light);
  --orange: var(--orange-light);
  --orange-auxiliary: var(--orange-auxiliary-light);
  --orange-stroke: var(--orange-stroke-light);
  --mint: var(--mint-light);
  --mint-auxiliary: var(--mint-auxiliary-light);
  --mint-stroke: var(--mint-stroke-light);
  --aqua: var(--aqua-light);
  --aqua-auxiliary: var(--aqua-auxiliary-light);
  --aqua-stroke: var(--aqua-stroke-light);
  --midnight: var(--midnight-light);
  --midnight-auxiliary: var(--midnight-auxiliary-light);
  --midnight-stroke: var(--midnight-stroke-light);
  --purple: var(--purple-light);
  --purple-auxiliary: var(--purple-auxiliary-light);
  --purple-stroke: var(--purple-stroke-light);
  --magenta: var(--magenta-light);
  --magenta-auxiliary: var(--magenta-auxiliary-light);
  --magenta-stroke: var(--magenta-stroke-light);
  --blue: var(--blue-light);
  --blue-auxiliary: var(--blue-auxiliary-light);
  --blue-stroke: var(--blue-stroke-light);
  --grey-auxiliary: var(--grey-auxiliary-light);
  // Deprecated
  --gold: var(--gold-light);
  --gold-auxiliary: var(--gold-auxiliary-light);

  // Other non-color vars
  --default-shadow: var(--default-shadow-light);
  --hover-shadow: var(--hover-shadow-light);
  --popover-shadow: var(--popover-shadow-light);
  --popover-shadow--right: var(--popover-shadow-light--right);
  --slider-shadow: var(--slider-shadow-light);
  --chartDimming: var(--chartDimming-light);

  // Chart SVG Stop Gradients
  --bgGradientHorizontal-color1: var(--bgGradientHorizontal-color1--light);
  --bgGradientHorizontal-color2: var(--bgGradientHorizontal-color2--light);

  --ai-gradient-background: var(--ai-gradient-background--light);

  // Overlays for loaders backgrounds or similar
  --overlay: var(--overlay-light);
}

// Dark Mode CSS Color Variables
@mixin dark-vars {
  // Base CSS Color Variables
  --blue1: var(--blue1-dark); // Accent Primary
  --blue2: var(--blue2-dark); // Accent Secondary
  --blue2-alt: var(--blue2-alt-dark); // Selected
  --blue3: var(--blue3-dark); // Accent Tertiary/Focused
  --blue3-alt: var(--blue3-alt-dark); // ??? Selected alt
  --blue4: var(--blue4-dark); // Accent Quaternary
  --blue5: var(--blue5-dark); // Accent Quinary
  --grey1: var(--grey1-dark); // UI Background
  --grey2: var(--grey2-dark); // UI Tertiary
  --grey2-alt: var(--grey2-alt-dark); // Hover
  --grey3: var(--grey3-dark); // Copy
  --grey3-alt: var(--grey3-alt-dark);
  --grey4: var(--grey4-dark); // Inactive / Lines
  --grey5: var(--grey5-dark); // UI Foreground
  --white: var(--white-dark);
  --scorp-black: var(--scorp-black-dark);
  --grey3-secondary: var(--grey3-secondary-dark); // UI Secondary
  --grey3-secondary-trigger: var(--grey3-secondary-trigger-dark); // UI Secondary Trigger

  // Semantic CSS Variables
  --accent-primary: var(--blue1);
  --accent-secondary: var(--blue2);
  --accent-tertiary: var(--blue3);
  --accent-quaternary: var(--blue4);
  --accent-quinary: var(--blue5);
  --selected: var(--blue2-alt);
  --focused: var(--blue3);
  --hover: var(--grey2-alt);
  --ui-background: var(--scorp-black);
  --ui-foreground: var(--grey5);
  --container: #232323;
  --ui-primary: var(--white);
  --ui-primary-reversed: var(--scorp-black);
  --copy: var(--grey3);
  --ui-secondary: var(--grey3-secondary);
  --ui-secondary-trigger: var(--grey3-secondary-trigger);
  --ui-tertiary: var(--grey2);
  --inactive: var(--grey4);
  --accent-hero: var(--accent-quaternary);

  // App Colors
  --platform-primary: var(--platform-primary-dark);
  --platform-secondary: var(--platform-secondary-dark);
  --marketing-secondary: var(--marketing-secondary-dark);
  --rep-secondary: var(--rep-secondary-dark);
  --comms-secondary: var(--comms-secondary-dark);
  --ops-secondary: var(--ops-secondary-dark);
  --payments-secondary: var(--payments-secondary-dark);
  --reporting-secondary: var(--reporting-secondary-dark);
  --customers-secondary: var(--customers-secondary-dark);
  --settings-secondary: var(--settings-secondary-dark);
  --settings-gradient1: var(--settings-gradient1-dark);
  --settings-gradient2: var(--settings-gradient2-dark);

  // NPS Colors
  --nps-positive: var(--nps-positive-dark);
  --nps-negative: var(--nps-negative-dark);
  --nps-positive-content: var(--nps-positive-content-dark);
  --nps-negative-content: var(--nps-negative-content-dark);

  // Chart Colors
  --chart-qualitative-1: var(--chart-qualitative-1-dark);
  --chart-qualitative-2: var(--chart-qualitative-2-dark);
  --chart-qualitative-3: var(--chart-qualitative-3-dark);
  --chart-qualitative-4: var(--chart-qualitative-4-dark);
  --chart-qualitative-5: var(--chart-qualitative-5-dark);
  --chart-qualitative-6: var(--chart-qualitative-6-dark);
  --chart-qualitative-7: var(--chart-qualitative-7-dark);
  --chart-qualitative-8: var(--chart-qualitative-8-dark);
  --chart-qualitative-9: var(--chart-qualitative-9-dark);
  --chart-qualitative-10: var(--chart-qualitative-10-dark);

  --chart-diverging-1: var(--chart-diverging-1-dark);
  --chart-diverging-2: var(--chart-diverging-2-dark);
  --chart-diverging-3: var(--chart-diverging-3-dark);
  --chart-diverging-4: var(--chart-diverging-4-dark);
  --chart-diverging-5: var(--chart-diverging-5-dark);
  --chart-diverging-6: var(--chart-diverging-6-dark);
  --chart-diverging-7: var(--chart-diverging-7-dark);
  --chart-diverging-8: var(--chart-diverging-8-dark);
  --chart-diverging-9: var(--chart-diverging-9-dark);
  --chart-diverging-10: var(--chart-diverging-10-dark);

  // Misc Colors
  --green: var(--green-dark);
  --green-auxiliary: var(--green-auxiliary-dark); // var(--green) @ 20%
  --green-stroke: var(--green-stroke-dark);
  --yellow: var(--yellow-dark);
  --yellow-auxiliary: var(--yellow-auxiliary-dark); // var(--yellow) @ 20%
  --yellow-stroke: var(--yellow-stroke-dark);
  --red: var(--red-dark);
  --red-auxiliary: var(--red-auxiliary-dark); // var(--red) @ 20%
  --red-stroke: var(--red-stroke-dark);
  --burgundy: var(--burgundy-dark);
  --burgundy-auxiliary: var(--burgundy-auxiliary-dark);
  --burgundy-stroke: var(--burgundy-stroke-dark);
  --brown: var(--brown-dark);
  --brown-auxiliary: var(--brown-auxiliary-dark);
  --brown-stroke: var(--brown-stroke-dark);
  --orange: var(--orange-dark);
  --orange-auxiliary: var(--orange-auxiliary-dark);
  --orange-stroke: var(--orange-stroke-dark);
  --mint: var(--mint-dark);
  --mint-auxiliary: var(--mint-auxiliary-dark);
  --mint-stroke: var(--mint-stroke-dark);
  --aqua: var(--aqua-dark);
  --aqua-auxiliary: var(--aqua-auxiliary-dark);
  --aqua-stroke: var(--aqua-stroke-dark);
  --midnight: var(--midnight-dark);
  --midnight-auxiliary: var(--midnight-auxiliary-dark);
  --midnight-stroke: var(--midnight-stroke-dark);
  --purple: var(--purple-dark);
  --purple-auxiliary: var(--purple-auxiliary-dark);
  --purple-stroke: var(--purple-stroke-dark);
  --magenta: var(--magenta-dark);
  --magenta-auxiliary: var(--magenta-auxiliary-dark);
  --magenta-stroke: var(--magenta-stroke-dark);
  --blue: var(--blue-dark);
  --blue-auxiliary: var(--blue-auxiliary-dark);
  --blue-stroke: var(--blue-stroke-dark);
  --grey-auxiliary: var(--grey-auxiliary-dark);
  // Deprecated
  --gold: var(--gold-dark);
  --gold-auxiliary: var(--gold-auxiliary-dark);

  // Other non-color vars
  --default-shadow: var(--default-shadow-dark);
  --hover-shadow: var(--hover-shadow-dark);
  --popover-shadow: var(--popover-shadow-dark);
  --popover-shadow--right: var(--popover-shadow-dark--right);
  --slider-shadow: var(--slider-shadow-dark);
  --chartDimming: var(--chartDimming-dark);

  // Chart SVG Stop Gradients
  --bgGradientHorizontal-color1: var(--bgGradientHorizontal-color1--dark);
  --bgGradientHorizontal-color2: var(--bgGradientHorizontal-color2--dark);

  --ai-gradient-background: var(--ai-gradient-background--dark);

  // Overlays for loaders backgrounds or similar
  --overlay: var(--overlay-dark);
}

// Base variables, mainly in case theme class is not applied
:root {
  // Forced CSS Color Variables
  // Light
  --blue1-light: #007ffd; // Accent Primary
  --blue2-light: #66aeff; // Accent Secondary
  --blue2-alt-light: #66aeff25; // Selected
  --blue3-light: #b2d6ff; // Accent Tertiary/Focused
  --blue3-alt-light: #e6f1ff; // ??? Selected alt
  --blue4-light: #d8eaff; // Accent Quaternary
  --blue5-light: #e6f1ff; // Accent Quinary
  --grey1-light: #f2f2f5; // UI Background
  --grey2-light: #bcbec7; // UI Tertiary
  --grey2-alt-light: #bcbec734; // Hover
  --grey3-light: #626265; // Copy
  --grey3-alt-light: #62626550;
  --grey4-light: #dddee3; // Inactive / Lines
  --grey5-light: #f8f8fa; // UI Foreground
  --white-light: #ffffff; // Container
  --scorp-black-light: #191919; // UI Primary
  --grey3-secondary-light: #86898f; // UI Secondary
  --grey3-secondary-trigger-light: rgba(134, 137, 143, 0.1); // UI Secondary Trigger

  // Dark
  --blue1-dark: #2591fc; // Accent Primary
  --blue2-dark: #66aeff; // Accent Secondary
  --blue2-alt-dark: #66aeff40; // Selected
  --blue3-dark: #155391; // Accent Tertiary/Focused
  --blue3-alt-dark: #18334f; // ??? Selected alt
  --blue4-dark: #1c354f; // Accent Quaternary
  --blue5-dark: #1e3143; // Accent Quinary
  --grey1-dark: #1e1e1e; // UI Background
  --grey2-dark: #707070; // UI Tertiary
  --grey2-alt-dark: #70707033; // Hover
  --grey3-dark: #b3b3b3; // Copy
  --grey3-alt-dark: #b3b3b350;
  --grey4-dark: #414141; // Inactive / Lines
  --grey5-dark: #191919; // UI Foreground
  --white-dark: #ffffff; // Container
  --scorp-black-dark: #191919; // UI Primary
  --grey3-secondary-dark: #8a8a8a; // UI Secondary
  --grey3-secondary-trigger-dark: rgba(138, 138, 138, 0.2); // UI Secondary Trigger

  // Chart Colors

  --chart-qualitative-1-light: #007ffd;
  --chart-qualitative-2-light: #4635de;
  --chart-qualitative-3-light: #0cacdf;
  --chart-qualitative-4-light: #47dbe4;
  --chart-qualitative-5-light: #496de7;
  --chart-qualitative-6-light: #57c2fc;
  --chart-qualitative-7-light: #7853bf;
  --chart-qualitative-8-light: #708aff;
  --chart-qualitative-9-light: #0e28a3;
  --chart-qualitative-10-light: #68eced;

  --chart-qualitative-1-dark: #007ffd;
  --chart-qualitative-2-dark: #382e93;
  --chart-qualitative-3-dark: #0cacdf;
  --chart-qualitative-4-dark: #47dbe4;
  --chart-qualitative-5-dark: #496de7;
  --chart-qualitative-6-dark: #57c2fc;
  --chart-qualitative-7-dark: #7853bf;
  --chart-qualitative-8-dark: #708aff;
  --chart-qualitative-9-dark: #68eced;
  --chart-qualitative-10-dark: #0943be;

  --chart-diverging-1-light: #0943be;
  --chart-diverging-2-light: #3372d0;
  --chart-diverging-3-light: #58a3ef;
  --chart-diverging-4-light: #68bff3;
  --chart-diverging-5-light: #84e2f3;
  --chart-diverging-6-light: #a9efce;
  --chart-diverging-7-light: #88e2ab;
  --chart-diverging-8-light: #5bd082;
  --chart-diverging-9-light: #2ec253;
  --chart-diverging-10-light: #14a724;

  --chart-diverging-1-dark: #0943be;
  --chart-diverging-2-dark: #3372d0;
  --chart-diverging-3-dark: #58a3ef;
  --chart-diverging-4-dark: #68bff3;
  --chart-diverging-5-dark: #84e2f3;
  --chart-diverging-6-dark: #a9efce;
  --chart-diverging-7-dark: #88e2ab;
  --chart-diverging-8-dark: #5bd082;
  --chart-diverging-9-dark: #2ec253;
  --chart-diverging-10-dark: #14a724;

  @include light-vars;

  // Back-Compat Variables (for mid-term support of c:{color} classes)
  --blue3-alt: #e6f1ff;
  --blue4: var(--blue4-light);
  --grey1-alt: #f2f2f580;
  --mandarin: var(--yellow);
  --aight: var(--yellow);
  --rose: var(--red);
  --bogus: var(--red);
  --lime: var(--green);
  --excellent: var(--green);
  --scorp-white: var(--white-light);
  --scorp-white-alt2: #ffffff70;
  --forced-grey2: var(--grey2-light);

  // App Colors - Light
  --platform-primary-light: var(--accent-primary);
  --platform-secondary-light: var(--blue3-alt);
  --marketing-secondary-light: #d2def5;
  --rep-secondary-light: #ccdef0;
  --comms-secondary-light: #d1e1ff;
  --ops-secondary-light: #cfeaf9;
  --payments-secondary-light: #ceeef9;
  --reporting-secondary-light: #dbe2fa;
  --customers-secondary-light: #ccf2f4;
  --settings-secondary-light: #e7e7e9;
  --settings-gradient1-light: var(--scorp-black);
  --settings-gradient2-light: var(--grey3-light);

  // App Colors - Dark
  --platform-primary-dark: var(--accent-primary);
  --platform-secondary-dark: var(--blue3-alt);
  --marketing-secondary-dark: #222e45;
  --rep-secondary-dark: #1c2e40;
  --comms-secondary-dark: #21314f;
  --ops-secondary-dark: #1f3a49;
  --payments-secondary-dark: #1e3e49;
  --reporting-secondary-dark: #2b324a;
  --customers-secondary-light: #1c4244;
  --settings-secondary-dark: #373739;
  --settings-gradient1-dark: var(--grey4-dark);
  --settings-gradient2-dark: var(--grey2-dark);

  // Misc Colors - Light
  --green-light: #23bf57;
  --green-auxiliary-light: #e9f9ee;
  --green-stroke-light: #23bf5766;
  --yellow-light: #edae03;
  --yellow-auxiliary-light: #fdf7e6;
  --yellow-stroke-light: #edae0366;
  --red-light: #d12b1c;
  --red-auxiliary-light: #faeae8;
  --red-stroke-light: #d12b1c66;
  --burgundy-light: #b32d4a;
  --burgundy-auxiliary-light: #f4dfe4;
  --burgundy-stroke-light: #b32d4a66;
  --brown-light: #74493e;
  --brown-auxiliary-light: #eae4e2;
  --brown-stroke-light: #74493e66;
  --orange-light: #f88622;
  --orange-auxiliary-light: #feedde;
  --orange-stroke-light: #f8862266;
  --mint-light: #1ab491;
  --mint-auxiliary-light: #dcf4ef;
  --mint-stroke-light: #1ab49166;
  --aqua-light: #24c2d0;
  --aqua-auxiliary-light: #def6f8;
  --aqua-stroke-light: #24c2d066;
  --midnight-light: #1f4686;
  --midnight-auxiliary-light: #dde3ed;
  --midnight-stroke-light: #1f468666;
  --purple-light: #7e51c5;
  --purple-auxiliary-light: #ece5f7;
  --purple-stroke-light: #7e51c566;
  --magenta-light: #ce51eb;
  --magenta-auxiliary-light: #f8e5fc;
  --magenta-stroke-light: #ce51eb66;
  --blue-light: var(--blue1-light);
  --blue-auxiliary-light: #d9ecff;
  --blue-stroke-light: #007ffd66;
  --grey-auxiliary-light: #ededee;
  // Deprecated
  --gold-light: #c7ab05;
  --gold-auxiliary-light: #fdf7e6;

  // Misc Colors - Dark
  --green-dark: #5acf81;
  --green-auxiliary-dark: #28342c;
  --green-stroke-dark: #5acf8166;
  --yellow-dark: #f2c342;
  --yellow-auxiliary-dark: #383326;
  --yellow-stroke-dark: #f2c34266;
  --red-dark: #dd6055;
  --red-auxiliary-dark: #362928;
  --red-stroke-dark: #dd605566;
  --burgundy-dark: #bd556b;
  --burgundy-auxiliary-dark: #32282a;
  --burgundy-stroke-dark: #bd556b66;
  --brown-dark: #7d625b;
  --brown-auxiliary-dark: #2c2929;
  --brown-stroke-dark: #7d625b66;
  --orange-dark: #ffa557;
  --orange-auxiliary-dark: #393028;
  --orange-stroke-dark: #ffa55766;
  --mint-dark: #41bfa2;
  --mint-auxiliary-dark: #263330;
  --mint-stroke-dark: #41bfa266;
  --aqua-dark: #51d0db;
  --aqua-auxiliary-dark: #283435;
  --aqua-stroke-dark: #51d0db66;
  --midnight-dark: #3f5e91;
  --midnight-auxiliary-dark: #26292e;
  --midnight-stroke-dark: #3f5e9166;
  --purple-dark: #9d7ecf;
  --purple-auxiliary-dark: #2f2c34;
  --purple-stroke-dark: #9d7ecf66;
  --magenta-dark: #e084f5;
  --magenta-auxiliary-dark: #362d38;
  --magenta-stroke-dark: #e084f566;
  --blue-dark: var(--blue1-dark);
  --blue-auxiliary-dark: #232e39;
  --blue-stroke-dark: #2591fc66;
  --grey-auxiliary-dark: #2d2d2d;
  // Deprecated
  --gold-dark: #cfb730;
  --gold-auxiliary-dark: #575027;

  // Other non-color vars
  // Light Colors
  --default-shadow-light: 0px 0.0625rem 0.125rem #19191915; // 0px 1px 2px
  --hover-shadow-light: 0px 0.625rem 1.875rem #19191915; // 0px 10px 30px
  --popover-shadow-light: 0px 0.625rem 1.875rem #19191933; // 0px 10px 30px
  --popover-shadow-light--right: 0.625rem 0px 1.875rem #19191933; // 10px 0px 30px
  --slider-shadow-light: 0px 0.0625rem 0.5rem 0px #19191933; // 0px 1px 8px 0px

  --chartDimming-light: contrast(22%) brightness(160%) saturate(1.4);

  // Dark Colors
  --default-shadow-dark: 0px 0.125rem 0.325rem #191919a6; // 0px 2px 5.2px
  --hover-shadow-dark: 0px 0.125rem 0.9375rem #191919a6; // 0px 2px 15px
  --popover-shadow-dark: 0px 0.625rem 0.9375rem #191919a6; // 0px 10px 15px
  --popover-shadow-dark--right: 0.625rem 0px 0.9375rem #191919a6; // 10px 0px 15px
  --slider-shadow-dark: 0px 0.0625rem 0.25rem 0px #191919a6; // 0px 1px 4px 0px

  --chartDimming-dark: contrast(22%) brightness(80%) saturate(1.4);

  // Chart SVG Stop Gradients
  --bgGradientHorizontal-color1--light: rgba(255, 255, 255, 0.4);
  --bgGradientHorizontal-color2--light: rgba(255, 255, 255, 0);
  --bgGradientHorizontal-color1--dark: rgba(35, 35, 35, 0.4);
  --bgGradientHorizontal-color2--dark: rgba(35, 35, 35, 0);

  // NPS Colors
  --nps-positive-light: var(--green-auxiliary-light);
  --nps-negative-light: var(--red-auxiliary-light);
  --nps-positive-content-light: var(--green-light);
  --nps-negative-content-light: var(--red-light);

  --nps-positive-dark: #0c4f23;
  --nps-negative-dark: #5d1510;
  --nps-positive-content-dark: var(--green-auxiliary-light);
  --nps-negative-content-dark: var(--red-auxiliary-light);

  // AI Background Gradient
  --ai-gradient-background--light: linear-gradient(
    180deg,
    rgba(109, 89, 255, 0.2) 6.25%,
    rgba(0, 127, 253, 0.2) 93.75%
  );
  --ai-gradient-background--dark: linear-gradient(180deg, rgba(109, 89, 255, 0.2) 6.25%, rgba(0, 127, 253, 0.2) 93.75%);

  // Overlays for loaders backgrounds or similar
  --overlay-light: rgba(255, 255, 255, 0.5);
  --overlay-dark: rgba(0, 0, 0, 0.5);
}

// Applying Auto Light Mode CSS Color Variables - commented until dark mode options are added
@media (prefers-color-scheme: light) {
  html.auto-mode,
  // body rule added for storybook
  body.auto-mode {
    background: var(--white);
    @include light-vars;
  }
}

// Applying Forced Light Mode CSS Color Variables
html.light-mode,
// body rule added for storybook
body.light-mode {
  background: var(--white);
  @include light-vars;
}

// Applying Auto Dark Mode CSS Color Variables - commented until dark mode options are added
@media (prefers-color-scheme: dark) {
  html.auto-mode,
  // body rule added for storybook
  body.auto-mode {
    background: c.$darkContainer;
    @include dark-vars;
  }
}

// Applying Forced Dark Mode CSS Color Variables
html.dark-mode,
// body rule added for storybook
body.dark-mode, .sb-show-main.dark-mode {
  background: c.$darkContainer;
  @include dark-vars;
}

// !!Deprecated - V2 Additions (use existing semantic variables)
html {
  // Updated to semantic variables
  --global-primary: var(--accent-primary);
  --global-secondary: var(--blue4);
  --global-text: var(--ui-primary);
  --global-subtext: var(--grey3-secondary);
  --global-copy: var(--copy);

  --link-primary: var(--accent-primary);
  --link-primary-hover: var(--accent-secondary);

  --global-disabled: var(--grey2-alt);

  --card-background: var(--ui-foreground);
  --app-background: var(--ui-background);
  --link-secondary-pressed: var(--focused);

  // Needs dark vars
  --link-secondary-hover: var(--blue5);
  --link-secondary: var(--blue4);
  --link-secondary-disabled: var(--blue4);

  --settings-black1: var(--scorp-black);

  // Unrepresented Colors
  --global-tertiary: var(--platform-primary);
  --global-icon-box-bg: var(--platform-secondary);

  --link-primary-pressed: #1867ff;
  --link-primary-disabled: #bfdffe;

  --card-background-hover: #e6eaee;

  // Deprecated Overrides - Remove after suite colors styles are fully removed platform-wide:
  --marketing-blue1: var(--accent-primary);
  --marketing-blue2: var(--accent-secondary);
  --marketing-blue3: var(--accent-quaternary);
  --marketing-blue4: var(--accent-quinary);

  --rep-blue1: var(--accent-primary);
  --rep-blue2: var(--accent-secondary);
  --rep-blue3: var(--accent-quaternary);
  --rep-blue4: var(--accent-quinary);

  --comms-blue1: var(--accent-primary);
  --comms-blue2: var(--accent-secondary);
  --comms-blue3: var(--accent-quaternary);
  --comms-blue4: var(--accent-quinary);

  --ops-blue1: var(--accent-primary);
  --ops-blue2: var(--accent-secondary);
  --ops-blue3: var(--accent-quaternary);
  --ops-blue4: var(--accent-quinary);

  --payments-blue1: var(--accent-primary);
  --payments-blue2: var(--accent-secondary);
  --payments-blue3: var(--accent-quaternary);
  --payments-blue4: var(--accent-quinary);

  --reporting-blue1: var(--accent-primary);
  --reporting-blue2: var(--accent-secondary);
  --reporting-blue3: var(--accent-quaternary);
  --reporting-blue4: var(--accent-quinary);

  --customers-blue1: var(--accent-primary);
  --customers-blue2: var(--accent-secondary);
  --customers-blue3: var(--accent-quaternary);
  --customers-blue4: var(--accent-quinary);
  // End of deprecated overrides

  --chart-gradient-low: #ffdc6e;
  --chart-gradient-medium: linear-gradient(#d0e450, #ffdc6e);
  --chart-gradient-high: linear-gradient(#23bf57, #d0e450, #ffdc6e);
  --chart-gradient-perfect: #23bf57;

  --app-gradient: var(--global-tertiary);

  --tooltip: #09325c;
}

@each $cat, $color in c.$app-highlight-colors {
  .app-highlight-#{$cat} {
    color: $color;
  }
}

// Sets up the app background colors
.appGradBG {
  @each $cat, $color in c.$app-colors {
    &\:#{$cat} {
      background-color: scale-color($color, $lightness: -50%);
      background-image: radial-gradient(30% 42% at -5% -5%, scale-color($color, $lightness: -20%), transparent),
        radial-gradient(35% 60% at 70% 50%, scale-color($color, $lightness: -20%), transparent),
        radial-gradient(44% 64% at 0 112%, scale-color($color, $lightness: -80%), transparent);
    }
  }
}

// TEMP - Until storybook.css works
.sb-show-main {
  &.light-mode {
    background: #ffffff;
    color: #191919;
  }

  &.dark-mode {
    background: #000000;
    color: #ffffff;
  }
}
