@use '_layout' as l;

$cell-padding: 1.5rem;
$cell-padding-vertical: 0.5rem;
$cell-padding-vertical--condensed: 0.25rem;
$single-line-cell-padding: 1rem;
$single-line-cell-padding-mobile: 0.75rem;

table.scorpion-table {
  background-color: var(--container);
  border-spacing: 0;
  border-collapse: collapse;
  color: var(--copy);
  width: 100%;

  &.sticky {
    border-collapse: separate;

    // if the table is sticky, add border-bottom to th itself so it also sticks to top
    th {
      border-block: 1px solid var(--inactive) !important;
      visibility: visible !important;
      position: sticky;
      top: 0;
    }

    tr {
      border: none !important;
    }

    // if the table is sticky, add border-bottom to td itself instead of the tr, so it shows up correctly
    td {
      border-bottom: 1px solid var(--inactive) !important;
    }
  }

  thead {
    background-color: inherit;
    color: var(--ui-primary);
    text-align: left;
    transition:
      300ms background ease,
      300ms border ease;

    tr {
      background-color: inherit;
    }

    th {
      border-block: 1px solid var(--inactive);
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.714;
      padding: $cell-padding-vertical $cell-padding * 0.5;
      position: relative;
      user-select: none;
      background-color: inherit;
      transition:
        300ms background ease,
        300ms border ease;

      &:first-child {
        padding-left: $cell-padding;
      }

      &:last-child {
        padding-right: $cell-padding;
      }

      &[class*='e:'],
      &.thin {
        width: 1em;

        &:not(:first-child) {
          padding-left: 0;
        }

        &:not(:last-child) {
          padding-right: 0;
        }
      }

      // This overrides the default sticky _getCalculatedZIndex in sticky-styler.d.ts
      &.scorpion-table-sticky {
        z-index: 3 !important;
      }

      &:empty {
        visibility: hidden;
      }
    }
  }

  tbody {
    background-color: var(--container);
  }

  .select-col {
    width: 4.5rem;
  }

  &.base-styles {
    tr.scorpion-row:not(.empty-row) {
      @include l.table-row(false, false, false);
    }

    tr.empty-row {
      height: 4rem;
      background-color: var(--ui-foreground);
    }

    &.mod-grey {
      tr.scorpion-row:not(.empty-row) {
        background-color: var(--ui-foreground);
      }

      tr.empty-row {
        background-color: var(--container);
      }
    }

    &:not(.clean-first) td.scorpion-cell {
      &:first-child {
        font-size: 1rem;
        color: var(--ui-primary);
        font-weight: 600;
      }
    }

    td.scorpion-cell {
      height: 4rem;
      padding: $cell-padding-vertical $cell-padding * 0.5;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 400;
      color: var(--copy);

      &:first-child {
        padding-left: $cell-padding;
      }

      &:last-child {
        padding-right: $cell-padding;
      }

      &[class*='e:'],
      &.thin {
        width: 1em;

        &:not(:first-child) {
          padding-left: 0;
        }

        &:not(:last-child) {
          padding-right: 0;
        }
      }

      &.no-wrap {
        white-space: nowrap;
      }
    }

    &:not(.no-hover) tr.scorpion-row:not(.empty-row) {
      @include l.table-row-hover;
    }
  }

  &.interactive.interactive {
    tr.scorpion-row:not(.empty-row) {
      @include l.table-row-interactive;
    }

    &.has-action-bars {
      tr.scorpion-row {
        position: relative;

        scorpion-action-bar {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 100%;
          transition: opacity 300ms ease;
        }

        &.active {
          z-index: 3;

          scorpion-action-bar {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
  }

  &.search-results {
    background-color: var(--container);
    border-spacing: 0;

    thead {
      background: transparent;
      border-block: none;

      th {
        padding-block: $cell-padding-vertical--condensed !important;
        border-block: none;
      }
    }

    tr.scorpion-row {
      cursor: pointer;

      > td {
        transition: background-color 0.4s ease;
      }

      &:hover > td,
      &.hovered > td {
        background-color: var(--grey2-alt);
      }

      &.active {
        border: solid 1px var(--accent-secondary);
      }
    }

    td.scorpion-cell {
      height: 3rem;
      padding: $cell-padding-vertical $cell-padding * 0.5;

      &:first-child {
        color: var(--ui-primary);
        padding-left: $cell-padding;
      }

      &:not(:first-child) {
        font-size: 0.875em;
      }

      &:last-child {
        padding-right: $cell-padding;
      }
    }
  }

  &.condensed,
  &.cardtable {
    thead {
      th {
        padding-block: $cell-padding-vertical--condensed !important;
      }
    }

    tr.scorpion-row {
      transition: background-color 0.4s ease;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid var(--inactive);
      }

      &:hover,
      &.hovered {
        background-color: var(--grey2-alt);
      }

      &.active {
        border: solid 1px var(--accent-secondary);
      }
    }

    td.scorpion-cell {
      height: 3.5rem;
      padding: $cell-padding-vertical $cell-padding * 0.5;

      &:first-child {
        padding-left: $cell-padding;
      }

      &:last-child {
        padding-right: $cell-padding;
      }
    }
  }

  &.pageframework {
    thead {
      border: none;

      th {
        padding-block: $cell-padding-vertical--condensed !important;
        border: none;
      }
    }

    tr.scorpion-row {
      transition: background-color 0.4s ease;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid var(--inactive);
      }

      &:hover,
      &.hovered {
        background-color: var(--grey2-alt);
      }

      &.active {
        border: solid 2px var(--accent-primary);
      }
    }

    td.scorpion-cell {
      height: 100%;
      padding: 0.375rem $cell-padding * 0.25;

      &:first-child {
        padding-left: $cell-padding * 0.5;
      }

      &:last-child {
        padding-right: $cell-padding * 0.5;
      }
    }
  }

  &.cardtable {
    th:not(:first-child).scorpion-header-cell {
      text-align: right;
    }

    tr.scorpion-row {
      cursor: default;

      &:first-child {
        border-top: 1px solid var(--inactive);
      }

      &:hover,
      &.hovered {
        background: none;
      }
      td:not(:first-child).scorpion-cell {
        text-align: right;
      }
    }
  }

  &.cardtablecondensed {
    thead {
      th {
        line-height: 1.2;
        padding-block: 0.2rem !important;
      }
    }

    td.scorpion-cell {
      height: 2.5rem !important;
      padding: $cell-padding-vertical $cell-padding * 0.5;

      &:first-child {
        padding-left: $cell-padding;
      }

      &:last-child {
        padding-right: $cell-padding;
      }
    }

    th:not(:first-child).scorpion-header-cell {
      text-align: right;
    }

    tr.scorpion-row {
      transition: background-color 0.4s ease;
      cursor: default;

      &:first-child {
        border-top: 1px solid var(--inactive);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--inactive);
      }

      &:hover,
      &.hovered {
        background: none;
      }
      td:not(:first-child).scorpion-cell {
        text-align: right;
      }
    }
  }

  &.pageframeworkcharttable {
    tr.scorpion-row {
      border-bottom: 1px solid var(--inactive);
      cursor: default;

      &:first-child {
        border-top: 1px solid var(--inactive);
      }

      &:hover,
      &.hovered {
        background: none;
      }
    }
  }

  &.condensed {
    th.scorpion-header-cell {
      height: 2.5rem;
      color: var(--copy);
      background: var(--container);
      position: sticky;
      top: 0;
    }
  }

  &.condensed-search-results {
    td.scorpion-cell {
      height: 1rem;
      padding: $cell-padding-vertical $cell-padding * 0.5;

      &:first-child {
        padding-left: $cell-padding;
      }

      &:last-child {
        padding-right: $cell-padding;
      }
    }
  }

  &.secondary {
    $secondary-paddings: $cell-padding-vertical 2rem;

    th {
      padding: $secondary-paddings;
    }

    tr.empty-row {
      height: 4rem;
    }

    td.scorpion-cell {
      height: 4rem;
      padding: $secondary-paddings;
    }
  }

  &.hollow {
    tbody {
      background-color: transparent;
    }

    thead,
    thead th {
      border: none !important;
    }

    tr.scorpion-row:not(.empty-row) {
      border: none !important;
    }

    td.scorpion-cell {
      height: 4rem;
    }
  }

  &.accordion-table {
    tbody {
      background: none;
    }

    tr.scorpion-row {
      background: var(--container);
    }

    tr.scorpion-row:not(.empty-row) {
      cursor: pointer;

      &.active {
        border: none;
      }
    }

    td:not(.scorpion-cell) {
      height: 5rem;
      padding: $cell-padding-vertical $cell-padding * 0.5;
      border-top: 1px solid var(--inactive);

      &:first-child {
        font-size: 1.125em;
        color: var(--ui-primary);
        font-weight: 700;
        padding-right: 2rem;
        padding-left: $cell-padding;
      }

      &:last-child {
        padding-right: $cell-padding;
      }
    }
  }

  // Triple class for specificity
  &.single-line.single-line.single-line {
    th {
      padding: $cell-padding-vertical $single-line-cell-padding * 0.5;

      &:first-child {
        padding-left: $single-line-cell-padding;
      }

      &:last-child {
        padding-right: $single-line-cell-padding;
      }
    }

    td.scorpion-cell {
      height: 3rem;
      padding: $cell-padding-vertical $single-line-cell-padding * 0.5;

      &:first-child {
        padding-left: $single-line-cell-padding;
      }

      &:last-child {
        padding-right: $single-line-cell-padding;
      }
    }

    tr.empty-row {
      height: 3rem;
    }
  }

  &.mobile-scroll {
    th {
      text-align: left;
    }

    tr td {
      padding: 5.3rem 4rem 1rem 0.625rem !important;
      height: auto !important;
      white-space: nowrap;
      line-height: 1;
    }
  }

  // Requested by Design to avoid manually updating the popup component in all places.
  td scorpion-popup-menu {
    .button:not(.variant-stealth):focus,
    .button:not(.variant-stealth):hover {
      .button-inside:not(.variant-stealth) {
        background-color: var(--ui-secondary-trigger) !important;
        color: var(--ui-primary) !important;
      }
    }

    .button-inside:not(.variant-stealth) {
      background-color: transparent !important;
      color: var(--ui-primary) !important;
    }
  }
}

// Table Scroll Styles
.table-scroll {
  position: relative;
  width: 100%;
  z-index: 2;
  margin: auto;
  overflow: auto;

  table {
    border-collapse: separate;
    width: 100%;

    thead {
      border-block: none !important;

      th {
        position: sticky;
        top: 0;
        z-index: 4;
        background-color: var(--container);
        border-block: none;
        padding-block: 0.75rem !important;
        white-space: nowrap;

        // th borders
        &::before,
        &::after {
          background: var(--inactive);
          content: '';
          height: 1px;
          left: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 6;
        }

        &::after {
          bottom: 0;
          top: auto;
        }

        &:first-child {
          &::before {
            background: none;
            border-bottom: 1px solid var(--inactive);
            border-top: 1px solid var(--inactive);
            content: '';
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 6;
          }
        }
      }
    }

    tr:not(.empty-row) {
      border: none !important;

      &:hover {
        border: none !important;
      }
    }

    td:not(.scorpion-column-empty) {
      border-bottom: 1px solid var(--inactive);
    }

    th:not(:first-child),
    td:not(.scorpion-column-empty):not(:first-child) {
      padding-inline: 2rem 0 !important;
    }

    th:first-child,
    td:first-child:not(.scorpion-column-empty) {
      position: sticky;
      left: 0;
    }

    th:first-child {
      z-index: 5;
    }

    td:first-child:not(.scorpion-column-empty) {
      transition: background 300ms ease;
      z-index: 3;

      &::before {
        content: '';
        inset: 0;
        position: absolute;
        pointer-events: none;
        transition: background 300ms ease;
        z-index: -1;
      }
    }

    td:first-child,
    th:first-child {
      background: var(--container);
    }

    td:first-child:not(.scorpion-column-empty),
    th:first-child:not(.scorpion-column-empty) {
      border-right: none;

      &::after {
        content: '';
        height: 100%;
        display: block;
        left: 100%;
        top: 0;
        position: absolute;
        border-left: 1px solid var(--inactive);
        width: 16rem;
        background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(255, 255, 255, 0.2) 8.37%, transparent 50%);
        pointer-events: none;

        @media (prefers-color-scheme: dark) {
          .auto-mode & {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(35, 35, 35, 0.2) 8.37%, transparent 50%);
          }
        }

        .dark-mode & {
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(35, 35, 35, 0.2) 8.37%, transparent 50%);
        }
      }
    }

    &.interactive.interactive {
      tr.scorpion-row,
      tr.scorpion-row td:first-child {
        transition: background 300ms ease;
      }

      tr.scorpion-row:not(.empty-row).active {
        background: var(--accent-quinary);

        td:first-child {
          background: var(--accent-quinary);
        }
      }

      tr.scorpion-row:not(.empty-row) {
        &:not(.active):hover {
          background: var(--grey3-secondary-trigger);

          td:first-child {
            background: var(--container);

            &::before {
              background: var(--grey3-secondary-trigger);
            }
          }
        }
      }
    }
  }
}

.content-expanded {
  th.scorpion-header-cell,
  td.scorpion-cell {
    &.scorpion-column-slideout-hidden {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  th.scorpion-header-cell,
  td.scorpion-cell {
    &.scorpion-column-mobile-hidden {
      display: none;
    }
  }

  table.scorpion-table {
    // Triple class for specificity
    &.single-line.single-line.single-line {
      th {
        padding: $cell-padding-vertical--condensed $single-line-cell-padding-mobile * 0.5;

        &:first-child {
          padding-left: $single-line-cell-padding-mobile;
        }

        &:last-child {
          padding-right: $single-line-cell-padding-mobile;
        }
      }

      td.scorpion-cell {
        height: 3.5rem;
        padding: $cell-padding-vertical $single-line-cell-padding-mobile * 0.5;

        &:first-child {
          padding-left: $single-line-cell-padding-mobile;
        }

        &:last-child {
          padding-right: $single-line-cell-padding-mobile;
        }
      }

      tr.empty-row {
        height: 3.5rem;
      }
    }
  }
}
