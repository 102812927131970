@use 'var' as v;
@use 'flex.mixin' as m;

$gaps: (
  '05': 0.25,
  '10': 0.5,
  '15': 0.75,
  '20': 1,
  '25': 1.25,
  '30': 1.5,
  '40': 2,
  '50': 2.5,
  '60': 3,
  '70': 3.5,
  '80': 4,
);

[class*='nlf'] {
  --full: 100%;
  --half: 50%;
  --third: calc(100% / 3);
  --two-thirds: calc(var(--third) * 2);
  --fourth: 25%;
  --three-fourths: 75%;
  --fifth: 20%;
  --two-fifths: 40%;
  --three-fifths: 60%;
  --four-fifths: 80%;
  --sixth: calc(100% / 6);
  --five-sixths: calc(var(--sixth) * 5);
  display: flex;

  &[class*='-inline'] {
    display: inline-flex;
  }

  &[class*='-wrap'] {
    flex-wrap: wrap;
  }

  &[class*='-center'] {
    justify-content: center;
  }

  &[class*='-start'] {
    justify-content: flex-start;
  }

  &[class*='-end'] {
    justify-content: flex-end;
  }

  &[class*='-between'] {
    justify-content: space-between;
  }

  &[class*='-around'] {
    justify-content: space-around;
  }

  &[class*='-evenly'] {
    justify-content: space-evenly;
  }

  &[class*='-middle'] {
    align-items: center;
  }

  &[class*='-top'] {
    align-items: flex-start;
  }

  &[class*='-bottom'] {
    align-items: flex-end;
  }

  &[class*='-baseline'] {
    align-items: baseline;
  }

  &[class*='-row'] {
    flex-direction: row;
  }

  &[class*='-reverse'] {
    flex-direction: row-reverse;
  }

  &[class*='-column'] {
    flex-direction: column;
  }

  &[class*='-reverse'][class*='-column'] {
    flex-direction: column-reverse;
  }

  & .fit {
    flex: 0 0 auto;
  }

  & .auto {
    flex: 1 1 auto;
  }

  & .fit-auto {
    flex: 0 1 auto;
  }

  & .auto.fit,
  & .auto-fit {
    flex: 1 0 auto;
  }

  & > .stretch-self {
    align-self: stretch;
  }

  & > .center-self {
    align-self: center;
  }

  // cm w/ gap | all directions
  @each $size, $gap in $gaps {
    &.cm\:#{$size} {
      --gap-#{$size}: #{$gap}rem;
      --gap-#{$size}-half: calc(var(--half) - (var(--gap-#{$size}) / 2));
      --gap-#{$size}-third: calc(var(--third) - (var(--gap-#{$size}) / 3 * 2));
      --gap-#{$size}-two-thirds: calc(var(--two-thirds) - (var(--gap-#{$size}) / 3));
      --gap-#{$size}-fourth: calc(var(--fourth) - (var(--gap-#{$size}) / 4 * 3));
      --gap-#{$size}-three-fourths: calc(var(--three-fourths) - (var(--gap-#{$size}) / 4));
      --gap-#{$size}-fifth: calc(var(--fifth) - (var(--gap-#{$size}) / 5 * 4));
      --gap-#{$size}-two-fifths: calc(var(--two-fifths) - (var(--gap-#{$size}) / 5 * 3));
      --gap-#{$size}-three-fifths: calc(var(--three-fifths) - (var(--gap-#{$size}) / 5 * 2));
      --gap-#{$size}-four-fifths: calc(var(--four-fifths) - (var(--gap-#{$size}) / 5));
      --gap-#{$size}-sixth: calc(var(--sixth) - (var(--gap-#{$size}) / 6 * 5));
      --gap-#{$size}-five-sixths: calc(var(--five-sixths) - (var(--gap-#{$size}) / 6));
      gap: var(--gap-#{$size});

      > .half {
        width: var(--gap-#{$size}-half);
      }

      > .third {
        width: var(--gap-#{$size}-third);
      }

      > .two-thirds {
        width: var(--gap-#{$size}-two-thirds);
      }

      > .fourth {
        width: var(--gap-#{$size}-fourth);
      }

      > .three-fourths {
        width: var(--gap-#{$size}-three-fourths);
      }

      > .fifth {
        width: var(--gap-#{$size}-fifth);
      }

      > .two-fifths {
        width: var(--gap-#{$size}-two-fifths);
      }

      > .three-fifths {
        width: var(--gap-#{$size}-three-fifths);
      }

      > .four-fifths {
        width: var(--gap-#{$size}-four-fifths);
      }

      > .sixth {
        width: var(--gap-#{$size}-sixth);
      }

      > .five-sixths {
        width: var(--gap-#{$size}-five-sixths);
      }
    }
  }
}

// cm w/ margin | vertical only
@each $size, $gap in $gaps {
  .cm\:#{$size}:not([class*='nlf']) {
    --cm-#{$size}: #{$gap}rem;

    > :not(:last-child) {
      margin-bottom: var(--cm-#{$size});
    }
  }
}

$merged-space-map: map-merge(v.$spaces, v.$spacesNiche);
// we add 0 for the first run, they will never change.
$merged-space-map2: map-merge($merged-space-map, v.$spaceNuller);

$valueOpt: m.nlpValuinator($merged-space-map2);
// loops through map creating , scope 'norm'
@each $padStyleMod2, $styleValue in $valueOpt {
  @each $style2, $value2 in $styleValue {
    %pad-norm-#{$padStyleMod2} {
      padding-#{$style2}: v.get-rem($value2);
    }
  }
}

// base values
@include m.nlp_nlf(v.$spaces, 'norm');
// niche values
@include m.nlp_nlf(v.$spacesNiche, 'norm');
// 0 values
@include m.nlp_nlf(v.$spaceNuller, 'norm');

// Divider styles
[class*='nlf'] {
  &:not([class*='-wrap']) {
    &.chd\:divider {
      --chddivider: var(--inactive);

      // Generic styles
      > *:not(.chd\:divide-ignore) + * {
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: var(--chddivider);
        }
      }

      // Row styles
      &:not([class*='-column']) {
        > *:not(.chd\:divide-ignore) + * {
          &::after {
            height: 100%;
            width: 1px;
            top: 0;
          }
        }

        @each $size, $gap in $gaps {
          &.cm\:#{$size} {
            --gap-#{$size}: #{$gap}rem;

            > *:not(.chd\:divide-ignore) + *::after {
              left: calc((0px - (var(--gap-#{$size}))) / 2);
            }
          }
        }
      }

      // Column styles
      &[class*='-column'] {
        > *:not(:first-child) {
          &::after {
            height: 1px;
            width: 100%;
            left: 0;
          }
        }

        &:not([class*='cm:']) {
          > *:not(.chd\:divide-ignore) + *::after {
            top: 0;
          }
        }

        @each $size, $gap in $gaps {
          &.cm\:#{$size} {
            --gap-#{$size}: #{$gap}rem;

            > *:not(.chd\:divide-ignore) + *::after {
              top: calc((0px - var(--gap-#{$size})) / 2);
            }
          }
        }
      }
    }
  }
}

.page-framework-gap {
  &-leads {
    gap: 1.5rem !important; // 24px

    @media (max-width: 768px) {
      gap: 1.125rem !important; // 18px
    }
  }
}
