@use 'sass:math';

@use '_colors' as c;

/**
* SHOULD BE REMOVED ONCE SCSS UTILIZING THIS IS GONE IN V2
* Will generate a value for the space left between a max width & the edge of the window
* It would be equal to the auto value in condition: max-width: N; margin-left/right: auto
* $width: total width we are evaluating to get the auto value
*/
@function compMain($width: 1920px) {
  @return calc((100vw - #{$width}) / 2);
}

/* Scroll */
/* Theme Options: light, dark, light-transparent, dark-transparent */
@mixin scrollbar($theme: 'light', $buffer: 0, $scrollBuffer: 4) {
  --thumb-color: transparent;
  --thumb-color-active: var(--ui-tertiary);
  --track-color: transparent;

  overflow: auto;
  overflow: overlay;

  @if ($buffer != 0) {
    margin-left: -$buffer;
    margin-right: -$buffer;
    padding-left: $buffer;
    padding-right: $buffer;
  }

  @if ($theme == 'dark') {
    --thumb-color-active: var(--copy);
  }

  @if ($theme == 'light-transparent') {
    --thumb-color-active: scale-color(c.$white, $alpha: -30%);
  }

  @if ($theme == 'dark-transparent') {
    --thumb-color-active: scale-color(c.$scorp-black, $alpha: -75%);
  }

  /* The not ::-webkit-scrollbar is necessary since Jan 2024
  After the update, if the official css scrollbar-color or scrollbar-width are set
  then Chrome will ignore all other -webkit scrollbar styles*/
  @supports (scrollbar-width: auto) and (not selector(::-webkit-scrollbar)) {
    scrollbar-color: var(--thumb-color-active) var(--track-color);
    scrollbar-width: #{6px + ($scrollBuffer * 2)};
  }

  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      width: #{6px + ($scrollBuffer * 2)};
      height: #{6px + ($scrollBuffer * 2)};
      background: none;
      transition: width 0.3s ease-in-out;
    }

    &::-webkit-scrollbar-track {
      background: var(--track-color);
      border-radius: 3rem;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-radius: 3rem;
      background-color: var(--thumb-color);
      border: solid #{$scrollBuffer + 'px'} transparent;
      transition:
        border 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: var(--thumb-color-active);
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    &:hover {
      --fix: ; /* This custom property invalidates styles on hover, thereby enforcing a style recomputation. This is needed to work around a bug in Safari. */
    }
  }
}

/* Icon Controls */
/* Set $lightBackground to false for use on a dark background (light circle) */
@mixin hover-circle($lightBackground: 'true', $beforeBuffer: 66.7%, $onHover: 'true') {
  position: relative;
  cursor: pointer;

  $focus-border: 4px;

  scorpion-icon:only-child,
  scorpion-svg-icon:only-child {
    display: block;
    position: relative;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: calc(100% + #{$beforeBuffer});
    width: calc(100% + #{$beforeBuffer});
    top: calc(#{math.div($beforeBuffer, -2)} - #{$focus-border});
    left: calc(#{math.div($beforeBuffer, -2)} - #{$focus-border});
    background-color: transparent;
    box-sizing: content-box;
    border-radius: 50%;
    background-clip: content-box;
    border: solid $focus-border transparent;
    transform: scale(0.8);
    transition:
      background-color 150ms,
      transform 150ms;
  }

  &.readonly {
    cursor: default;
  }

  @if ($onHover == 'true') {
    &:not(.readonly) {
      &:hover,
      &:focus {
        &::before {
          pointer-events: auto;
          transform: none;
          @if ($lightBackground == 'true') {
            background-color: scale-color(c.$scorp-black, $alpha: -95%);
          } @else {
            background-color: scale-color(c.$grey1, $alpha: -90%);
          }
        }
      }

      &:focus:not(:focus-visible)::before {
        background-color: transparent;
        transform: scale(0.8);
      }

      &:focus-visible,
      &:focus:hover {
        &::before {
          transform: none;
          @if ($lightBackground == 'true') {
            background-color: scale-color(c.$scorp-black, $alpha: -95%);
          } @else {
            background-color: scale-color(c.$grey1, $alpha: -90%);
          }
        }
      }

      /* -mox-focusring has to be separated from
      * the previous rule to prevent the whole rule
      * from being disabled in other browsers */
      &:-moz-focusring {
        &::before {
          transform: none;
          @if ($lightBackground == 'true') {
            background-color: scale-color(c.$scorp-black, $alpha: -95%);
          } @else {
            background-color: scale-color(c.$grey1, $alpha: -90%);
          }
        }
      }

      &:active::before {
        pointer-events: auto;
        @if ($lightBackground == 'true') {
          // !important to overcome extra specificity for backwards compatibility rules above
          background-color: scale-color(c.$scorp-black, $alpha: -90%) !important;
        } @else {
          background-color: scale-color(c.$grey1, $alpha: -80%) !important;
        }
      }
    }
  }
}

@mixin hover-circle-hoverable($lightBackground: 'true') {
  &::before {
    pointer-events: auto;
    transform: none;
    @if ($lightBackground == 'true') {
      background-color: scale-color(c.$scorp-black, $alpha: -95%);
    } @else {
      background-color: scale-color(c.$grey1, $alpha: -90%);
    }
  }
}

/* Set $lightBackground to false for use on a dark background (light circle) */
@mixin hover-squircle($lightBackground: 'true', $size: 2.25rem, $onHover: 'false') {
  cursor: pointer;
  position: relative;
  transition: color 300ms ease;

  scorpion-icon:only-child,
  scorpion-svg-icon:only-child {
    display: block;
    position: relative;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: $size;
    width: $size;
    top: 50%;
    left: 50%;
    background-color: transparent;
    box-sizing: content-box;
    border-radius: 0.5rem;
    background-clip: content-box;
    transform: scale(0.8) translate(-50%, -50%);
    transition:
      background-color 300ms ease,
      transform 300ms ease;
  }

  &.readonly {
    cursor: default;
  }

  @if ($onHover == 'false') {
    &::before {
      pointer-events: auto;
      transform: scale(1) translate(-50%, -50%);
      z-index: -1;
      @if ($lightBackground == 'true') {
        background-color: var(--grey3-secondary-trigger);
      } @else {
        background-color: rgb(0 127 253 / 10%);
      }
    }
  }

  &:not(.readonly) {
    &:hover,
    &:focus {
      color: var(--accent-primary);
      &::before {
        pointer-events: auto;
        transform: scale(1) translate(-50%, -50%);
        @if ($lightBackground == 'true') {
          background-color: scale-color(c.$blue1, $alpha: -95%);
        } @else {
          background-color: scale-color(c.$blue1, $alpha: -90%);
        }
      }
    }

    &:focus:not(:focus-visible) {
      color: var(--accent-primary);
      &::before {
        background-color: transparent;
        transform: scale(0.8) translate(-50%, -50%);
      }
    }

    &:focus-visible,
    &:focus:hover {
      color: var(--accent-primary);
      &::before {
        transform: scale(1) translate(-50%, -50%);
        @if ($lightBackground == 'true') {
          background-color: scale-color(c.$blue1, $alpha: -95%);
        } @else {
          background-color: scale-color(c.$blue1, $alpha: -90%);
        }
      }
    }

    /* -mox-focusring has to be separated from
    * the previous rule to prevent the whole rule
    * from being disabled in other browsers */
    &:-moz-focusring {
      color: var(--accent-primary);
      &::before {
        transform: scale(1) translate(-50%, -50%);
        @if ($lightBackground == 'true') {
          background-color: scale-color(c.$blue1, $alpha: -95%);
        } @else {
          background-color: scale-color(c.$blue1, $alpha: -90%);
        }
      }
    }

    &:active {
      &::before {
        pointer-events: auto;
        @if ($lightBackground == 'true') {
          // !important to overcome extra specificity for backwards compatibility rules above
          background-color: scale-color(c.$blue1, $alpha: -90%) !important;
        } @else {
          background-color: scale-color(c.$blue1, $alpha: -80%) !important;
        }
      }
    }
  }
}

// Popover list/item styles
@mixin popover-menu {
  background: var(--container);
  border: 0.5px solid var(--inactive);
  box-shadow: var(--popover-shadow);
  border-radius: 0.75rem;

  $popover-menu-padding: 0.375rem;
  $popover-menu-margin: 0.375rem;

  &::after {
    content: '';
    pointer-events: none;
    height: calc(100% - #{$popover-menu-margin * 2});
    width: calc(100% - #{$popover-menu-margin + $popover-menu-padding});
    position: absolute;
    top: $popover-menu-margin;
    left: 0;
    box-shadow:
      inset 0 1.25em 0.75em -1em var(--container),
      inset 0 -1.25em 0.75em -1.7em var(--container);
  }

  .scroll {
    padding: $popover-menu-padding;
    margin: $popover-menu-margin;
    min-width: 13.5rem;
    overflow-y: auto;
    scroll-behavior: smooth;
    scroll-margin: 0;
  }

  & ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: none;
  }

  & ::-webkit-scrollbar-track {
    background: #f1f1f1;
    background: transparent;
    border-radius: 17px;
  }

  & ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 17px;
    background-color: var(--ui-tertiary);
  }

  & ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  & ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin popover-item($theme: 'light') {
  .label,
  &.label {
    padding: 0.425rem 1rem 0.4rem;
    line-height: 1.125em;
    background-color: transparent;
    transition: background-color 0.3s ease;
    border-radius: 0.25em;
    cursor: pointer;
    will-change: transform;
    min-height: 2rem;

    span {
      display: inline-block;
      max-width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:not(.fit):not(.fit-auto):not(.auto-fit) {
      flex: 1 1 auto;
    }

    &:not([class*='nlf']) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  @if ($theme == 'light') {
    .label,
    &.label {
      color: var(--ui-primary);
    }
    &.checked:not(.disabled) {
      .label,
      &.label {
        background-color: var(--blue3-alt);
      }
    }

    &:hover:not(.disabled):not(.checked) {
      .label,
      &.label {
        background-color: var(--grey2-alt);
      }
    }

    &:not(.disabled):not(.checked),
    &:not(.disabled):not(.checked) {
      .label:focus-visible,
      &.label:focus-visible {
        background-color: var(--grey2-alt);
      }
    }

    &.disabled {
      cursor: default;

      .label {
        cursor: default;
        color: c.$scorp-grey-disabled;
      }

      &.label {
        color: c.$scorp-grey-disabled;
      }
    }
  }

  @if ($theme == 'dark') {
    .label,
    &.label {
      color: c.$scorp-white;
    }
    &.checked:not(.disabled) {
      .label,
      &.label {
        background-color: c.$scorp-grey-selected;
      }
    }

    &:hover:not(.disabled) {
      .label,
      &.label {
        background-color: c.$scorp-white-alt;
      }
    }
    &.disabled {
      cursor: default;

      .label {
        cursor: default;
        color: c.$scorp-grey-disabled;
      }

      &.label {
        color: c.$scorp-grey-disabled;
      }
    }
  }

  @if ($theme == 'search') {
    .label,
    &.label {
      padding: 0.5rem 1rem;
      background-color: var(--ui-background);
      transition:
        background-color 0.3s ease,
        box-shadow 0.3s ease;
      box-shadow: inset 0 0 0 2px transparent;
    }
    &.checked:not(.disabled) {
      .label,
      &.label {
        background-color: transparent;
        box-shadow: inset 0 0 0 2px var(--ui-tertiary);
      }
    }

    &:hover:not(.disabled) {
      .label,
      &.label {
        background-color: transparent;
        box-shadow: inset 0 0 0 2px var(--ui-tertiary);
      }
    }
    &.disabled {
      cursor: default;

      .label {
        cursor: default;
        opacity: 0.6;
      }

      &.label {
        opacity: 0.6;
      }
    }
  }
}

/* Divider */
@mixin divider($offset: 4, $backgroundColor: var(--inactive)) {
  $double-offset: $offset * 2;
  background-color: $backgroundColor;
  height: 1px;
  width: calc(100% + #{$double-offset}em);
  margin-left: -#{$offset}em;
  margin-right: -#{$offset}em;
}

// Setup to allow all screenreaders to still access while still hiding the element
@mixin ada-hide() {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

/* Table Row */
@mixin table-row($interactive: false, $hover: true, $hasBackground: true) {
  border-bottom: 1px solid var(--inactive);
  transition:
    300ms background ease,
    300ms border ease;

  @if ($interactive == true) {
    @include table-row-interactive;
  }

  @if ($hover == true and $interactive == false) {
    @include table-row-hover;
  }

  @if ($hasBackground == true) {
    background-color: var(--container);
  }
}

@mixin table-row-hover() {
  &:hover,
  &:focus-visible,
  &:focus-within {
    background: var(--grey3-secondary-trigger);
  }
}

@mixin table-row-interactive() {
  cursor: pointer;

  &:hover,
  &:focus-visible,
  &:focus-within {
    background: var(--grey3-secondary-trigger);
  }

  // To overcome insane specificity of the :not() list in table.component.scss:~89
  &.active {
    background: var(--accent-quinary);
  }

  &:focus-within:not(.active) {
    background: var(--grey3-secondary-trigger);
  }

  &:hover:not(.active) {
    &:hover,
    &:focus-visible {
      background: var(--grey3-secondary-trigger);
    }
  }
}

@mixin scorpion-tooltip {
  color: var(--white);
  font-size: 0.875em;
  line-height: 1.571;
  padding: 1rem;
  position: relative;

  /* Styles from other elements in the normal scorpion-tooltip component */
  border-radius: 0.5rem;

  .title {
    color: var(--white);
  }

  .bg {
    background-color: var(--tooltip);
  }
}

@mixin ngx-chart-tooltips {
  padding: 0 !important;

  &.type-tooltip {
    background: transparent !important;
  }

  .tooltip-content {
    color: var(--white);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.571;
    position: relative;
    padding: 1rem;

    *:not(:empty) + :is(.tooltip-space, .tooltip-val) {
      display: block;
      margin-top: 0.5rem;
    }

    .tooltip-val,
    .tooltip-label {
      padding: 0;
    }

    .tooltip-label,
    .tooltip-title {
      font-size: 1rem;
      line-height: 1.5;
    }

    .tooltip-val {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

// Page Framework Host Styles Mixin
@mixin pageFrameworkWidgetHost($display: flex, $alignItems: center) {
  box-sizing: border-box;
  display: $display;
  height: 100%;
  width: 100%;

  @if (($display == 'flex') or ($display == 'inline-flex')) {
    align-items: $alignItems;
  }
}

@mixin only-safari() {
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    @content;
  }
}

@mixin only-firefox() {
  @-moz-document url-prefix() {
    @content;
  }
}
