@use 'sass:map';

// !STOP USING THESE NOW!!!
// !STOP USING THESE NOW!!!
// !STOP USING THESE NOW!!!

// Color Palette
// blues (WARNING: BLUES SHIFTED AROUND TO MATCH STYLEGUIDE)
$blue1: #007ffd;
$blue2: #66aeff;
$blue3: #b2d6ff;
$blue4: #2e73e5;
$blue5: #e6f1ff;

// greys
$grey1: #f2f2f5;
$grey2: #bcbec7;
$grey3: #626265;
$grey4: #dddee3;
$grey5: #f8f8fa;

// black / white
$white: #ffffff;
$scorp-white: $white;

$black: #000000;
$scorp-black: #191919;

$grey1-alt: scale-color($grey1, $alpha: -20%);

// Should delete but shared components still using.
$blue1-alt: #886fde; // https://xd.adobe.com/view/59d62064-4fe8-4220-a939-21b5910a4eac-0c85/screen/ce55bcc0-7162-4be7-a4ef-c94f6a476df4/specs/
$blue2-alt: scale-color($blue2, $alpha: -75%);
$blue3-alt: #e6f1ff;
$blue6: #5dadfe;
$blue6-alt: scale-color($blue6, $alpha: -50%);

$grey2-alt: scale-color($grey2, $alpha: -66%);

$grey3-alt: scale-color($grey3, $alpha: -50%);
$grey3-secondary: #86898f;

$scorp-white-alt: scale-color($white, $alpha: -85%);
$scorp-white-alt2: scale-color($scorp-white, $alpha: -30%);

$darkContainer: #232323;

// System & Alerts
$lime: #23bf57;
$excellent: $lime;

$mandarin: #edae03;
$aight: $mandarin;

$rose: #d12b1c;
$bogus: $rose;

$null: $grey3;

// Should delete.
$grey1-alt: scale-color($grey1, $alpha: -20%);

// Names are all that are needed here. Values no longer matter to _content.scss with the new css variables
$content-colors: (
  // standard blues by styleguide
  'blue1': $blue1,
  'blue2': $blue2,
  'blue3': $blue3,
  'blue4': $blue4,
  'blue5': $blue5,
  // standard greys by styleguide
  'grey1': $grey1,
  'grey1-alt': $grey1-alt,
  'grey2': $grey2,
  'grey3': $grey3,
  'grey3-alt': $grey3-alt,
  'grey3-secondary': $grey3-secondary,
  'grey4': $grey4,
  // status
  'mandarin': $mandarin,
  'aight': $mandarin,
  'rose': $rose,
  'bogus': $rose,
  'lime': $lime,
  'excellent': $lime,
  // black & white
  'scorp-white': $scorp-white,
  'scorp-white-alt2': $scorp-white-alt2,
  'scorp-black': $scorp-black,
  // new system vars (light mode in this case)
  'accent-primary': $blue1,
  'accent-secondary': $blue2,
  'accent-accent-quaternary': $blue4,
  'accent-accent-quinary': $blue5,
  'ui-background': $grey1,
  'container': $white,
  'ui-primary': $scorp-black,
  'ui-primary-reversed': $scorp-white,
  'copy': $grey3,
  'ui-secondary': $grey3-secondary,
  'ui-secondary-trigger': $grey3-secondary,
  'ui-tertiary': $grey2,
  'inactive': $grey4,
  'forced-grey2': $grey2,
  'accent-hero': $blue1,
  // For forced dark styles on dashboard
  // basic colors
  'green': #23bf57,
  'green-auxiliary': #e9f9ee,
  'yellow': #edae03,
  'yellow-auxiliary': #fdf7e6,
  'red': #d12b1c,
  'red-auxiliary': #faeae8,
  'burgundy': #b32d4a,
  'burgundy-auxiliary': #f4dfe4,
  'brown': #74493e,
  'brown-auxiliary': #eae4e2,
  'orange': #f88622,
  'orange-auxiliary': #feedde,
  'gold': #c7ab05,
  'gold-auxiliary': #fdf7e6,
  'mint': #1ab491,
  'mint-auxiliary': #dcf4ef,
  'aqua': #24c2d0,
  'aqua-auxiliary': #def6f8,
  'midnight': #1f4686,
  'midnight-auxiliary': #dde3ed,
  'purple': #7e51c5,
  'purple-auxiliary': #ece5f7,
  'magenta': #ce51eb,
  'magenta-auxiliary': #f8e5fc,
  'grey-auxiliary': #ededee
);

// standard shadow used for cards, tables, and popovers
$default-shadow: 0px 0.0625rem 0.125rem scale-color($scorp-black, $alpha: -85%); // 0px 1px 2px
$default-shadow-dark: 0px 0.125rem 0.325rem #191919a6; // 0px 2px 5.2px
$hover-shadow: 0px 0.625rem 1.875rem scale-color($scorp-black, $alpha: -85%); // 0px 10px 30px
$hover-shadow-dark: 0px 0.125rem 0.9375rem #191919a6; // 0px 2px 15px
$popover-shadow: 0px 0.625rem 1.875rem #19191926; // 0px 10px 30px
$popover-shadow-dark: 0px 0.625rem 0.9375rem #191919a6; // 0px 10px 15px

$skeleton-animation-duration: 2000ms;

@mixin light-shimmer {
  background-image: linear-gradient(243.1deg, transparent 17.95%, rgba(188 190 199 / 14.9%) 33.7%, transparent 77.62%);
}

@mixin dark-shimmer {
  background-image: linear-gradient(243.1deg, transparent 17.95%, rgba(255, 255, 255, 0.05) 33.7%, transparent 77.62%);
}

@mixin skele-basic($theme: 'auto') {
  line-height: 1;
  max-width: 100%;
  user-select: none;
  color: transparent;
  overflow: hidden;
  position: relative;
  background-color: rgba(188 190 199 / 10.2%);

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    animation: preload-shimmer $skeleton-animation-duration linear 0ms infinite normal;
  }

  @if ($theme == 'auto') {
    &::before {
      @include light-shimmer;
    }

    :host-context(html.dark-mode) & {
      &::before {
        @include dark-shimmer;
      }
    }
  }

  @if ($theme == 'light') {
    &::before {
      @include light-shimmer;
    }
  }

  @if ($theme == 'dark') {
    &::before {
      @include dark-shimmer;
    }
  }

  @keyframes preload-shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

$tile-select-item-multi-tone-colors: (
  '0': #007ffd,
  '1': #96cbff,
  '2': #886fde,
  '3': #26e7a6,
  '4': #6c7f91,
  '5': #cff67b,
  '6': #60cece,
  '7': #febc3b,
  '8': #ff7272,
  '9': #28445b,
);

$tile-select-item-single-tone-colors: (
  '0': #007ffd,
  '1': #5dadfe,
  '2': #96cbff,
  '3': #cfe7ff,
);

$avatar-colors: (
  '0': #00264d,
  '1': #1e5ace,
  '2': #005ab3,
  '3': #1867ff,
  '4': #007ffd,
  '5': #0d98e3,
  '6': #0cacdf,
  '7': #496de7,
);

// Badges
$badges-colors: (
  // var(--red-auxiliary-light)
  'urgent': #faeae8,
  // var(--orange-auxiliary-light)
  'high': #feedde,
  // var(--yellow-auxiliary-light)
  'medium': #fdf7e6,
  // var(--green-auxiliary-light)
  'low': #e9f9ee,
  // var(--blue-auxiliary-light)
  'chill': #d9ecff,
  // var(--grey-auxiliary-light)
  'default': #ededee
);

$badges-colors-dark-mode: (
  // var(--red-auxiliary-dark)
  'urgent': #362928,
  // var(--orange-auxiliary-dark)
  'high': #393028,
  // var(--yellow-auxiliary-dark)
  'medium': #62532c,
  // var(--green-auxiliary-dark)
  'low': #28342c,
  // var(--blue-auxiliary-dark)
  'chill': #232e39,
  // var(--grey-auxiliary-dark)
  'default': #484848
);

// deprecated, needs clean up and removal
$scorp-blue-mid-hover: scale-color($blue2, $alpha: -75%);
$scorp-grey-selected: scale-color($grey2, $alpha: -75%);
$scorp-grey-disabled: scale-color($grey2, $alpha: -50%);
$grey-wash: scale-color($grey1, $lightness: 45%); // #f8f8fa
$marine: #22c3c6;
$royal: #2c3cde;
$electric: #20beea;
$emerald: #24bf00;
$orchid: #a161f1;
$gold: #f1be2a;
$grey-dark: #535252;
$card-shadow-color: #2e5984;
$card-box-shadow: $default-shadow;
$stepper-background: #f8f8fa;
$page-header-border-grey: #dddee3;
$page-header-shadow: rgba(98, 172, 253, 0.26);
$tile-shadow: rgba(46, 89, 132, 0.2);
// end deprecated

$brand-colors: (
  'mandarin': $mandarin,
  'rose': $rose,
  'lime': $lime,
  // deprecated:
  'marine': $marine,
  'royal': $royal,
  'electric': $electric,
  'emerald': $emerald,
  'orchid': $orchid,
);

// Standard Gradient
@function app-grad($cat, $dir: 90deg) {
  @return linear-gradient($dir, map.get($app-colors, $cat) 0%, map.get($app-colors-light, $cat) 100%);
}

// App colors
$app-colors: (
  'tasks': #35c1c4,
  'marketing': #0789ba,
  'digitalPresence': #1e50bf,
  'insights': #3737a6,
  'reviews': #3737a6,
  'operations': #4b2d95,
  'communications': #3c0e65,
  'reporting': #3737a6,
  'settings': #525252,
  'platform': #452c87,
);

// App colors
$app-colors-light: (
  'tasks': #57d1d5,
  'marketing': #37b8e8,
  'digitalPresence': #3d70e2,
  'insights': #6666e6,
  'reviews': #6666e6,
  'operations': #8460dd,
  'communications': #7431af,
  'reporting': #6666e6,
  'settings': #767676,
  'platform': #5938ae,
);

// App Highlight colors
$app-highlight-colors: (
  'tasks': #68eaeb,
  'marketing': #2cc5ff,
  'digitalPresence': #5c8eff,
  'insights': #8989ff,
  'reviews': #8989ff,
  'operations': #946ff0,
  'communications': #9263b9,
  'reporting': #8989ff,
  'settings': #9d9d9d,
  'platform': #8a74c3,
);
