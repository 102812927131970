@use 'var' as v;
@use 'sass:math' as math;

/*
 * Sets up padding teirs & unique overwrites (nlp-p40-pt20)
 * MASSIVE ASSUMPTION: NLP assigned should have ( paddingValue >= CMvalue / 2 )
 * condition above is unsupported due to awkward visual UI. (small padding on the parent and SUPER spaced out children)
 */
@mixin nlp_nlf($spaceMap, $scope) {
  @each $class, $space in $spaceMap {
    #{v.$nlp}[class*='-p#{$class}'] {
      @extend %pad#{'-' + $scope}-top#{$space};
      @extend %pad#{'-' + $scope}-left#{$space};
      @extend %pad#{'-' + $scope}-bottom#{$space};
      @extend %pad#{'-' + $scope}-right#{$space};
    }

    @each $type, $position in v.$pad-type-list {
      #{v.$nlp}[class*='-#{$type}#{$class}'] {
        @if length($position) > 1 {
          @each $detail in $position {
            @extend %pad#{'-' + $scope}-#{$detail}#{$space};
          }
        } @else {
          @extend %pad#{'-' + $scope}-#{$position}#{$space};
        }
      }

      @each $class2, $space2 in $spaceMap {
        @if (index($spaceMap, $class $space) >= index($spaceMap, $class2 $space2) and $space != $space2) {
          $finalVal: $space - ($space2 * 0.5);

          @if length($position) > 1 {
            #{v.$nlp}[class*='-p#{$class}'][class*='-#{$type}#{$class2}'] {
              @each $detail in $position {
                @extend %pad#{'-' + $scope}-#{$detail}#{$finalVal};
              }
            }
          } @else {
            #{v.$nlp}[class*='-p#{$class}'][class*='-#{$type}#{$class2}'] {
              @extend %pad#{'-' + $scope}-#{$position}#{$finalVal};
            }
          }
        }
      }
    }
  }
}

// Value map generator. Gather values to create mixings with NLP map
@function nlpValuinator($spaceMap) {
  $returnMap: ();
  @each $padClassMod, $nlpClassMod in v.$pad-type-list {
    @if (length($nlpClassMod) == 1) {
      @each $spaceSelectorMod, $spaceValue in $spaceMap {
        $returnMap: map-merge(
          $returnMap,
          (
            $nlpClassMod +
              $spaceValue: (
                $nlpClassMod: $spaceValue,
              )
          )
        );

        @each $compareSpaceSelectorMod, $compareSpaceValue in $spaceMap {
          @if (
            index($spaceMap, $compareSpaceSelectorMod $compareSpaceValue) >=
              index($spaceMap, $spaceSelectorMod $spaceValue)
          ) {
            $compareVal: $compareSpaceValue - ($spaceValue * 0.5);

            @if ($compareVal >= 0) {
              $returnMap: map-merge(
                $returnMap,
                (
                  $nlpClassMod +
                    $compareVal: (
                      $nlpClassMod: $compareVal,
                    )
                )
              );
            }
          }
        }
      }
    }
  }
  @return $returnMap;
}
